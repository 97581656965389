<template>
  <div class="header">
    <img src="../assets/jakob_compressed.jpeg" style="width: 250px; height: auto; border-radius: 50%;">
    <div id="landing">
      <h1>Hi, I'm <span id="name">Jakob</span></h1>
      <p id="info">mechanical engineer</p>
      <p id="info">software hobbyist</p>
      <p id="info">3d-printing enthusiast</p>
    </div>
  </div>
  <h2 style="margin-top: 50px;">Experiences</h2>
  <div class="cv">
    <div>
      <a href="https://www.carbon-cleanup.com" target="_blank" rel="noopener noreferrer" style="text-decoration: none;"><h3>Carbon Cleanup GmbH</h3></a>
      <div class="position">
        <h4>CTO<span>01/2023 - current</span></h4>
        <h4>Lead Development Engineer<span>07/2021 - 12/2022</span></h4>
        <h4>Intern, Bachelor Thesis<span>04/2021 - 06/2022</span></h4>
      </div>
      <h3>SGL Composites GmbH</h3>
      <div class="position">
        <h4>Intern<span>12/2018 - 01/2019</span></h4>
      </div>
      <h3>FACC AG</h3>
      <div class="position">
        <h4>Intern, Process Optimization of a Composite Press Process<span>07/2016</span></h4>
      </div>
    </div>
  </div>
  <h2>Education</h2>
  <div class="cv">
    <h3>University of Applied Sciences Upper Austria, Wels</h3>
    <div class="position">
      <h4>Ligthweight Design and Composite Materials, Bachelor of Science<span>03/2019 - 11/2021</span></h4>
    </div>
    <h3>Secondary Highschool for Mechanical Engineering, Ried im Innkreis</h3>
    <div class="position">
      <h4>Mechanical Engineering with focus on Ligthweight Design<span>09/2012 - 06/2017</span></h4>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'HomeView',
  mounted() {
    const blob = document.getElementById("blob");
    document.body.onpointermove = event => {
      const { clientX, clientY } = event;
      blob.animate({
        left: `${clientX}px`,
        top: `${clientY}px`
      }, {duration: 3000, fill: "forwards"})
    }
  },
}
</script>
